import { tv } from 'tailwind-variants';

export const heading = tv({
  base: 'font-bold',
  variants: {
    size: {
      display: 'text-6xl leading-tight',
      h1: 'text-5xl leading-tight',
      h2: 'text-4xl leading-normal',
      h3: 'text-3xl leading-normal',
      h4: 'text-2xl leading-normal',
      h5: 'text-xl leading-normal',
      h6: 'text-lg leading-tight',
    },
    weight: {
      medium: 'font-medium',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    size: 'h1',
    weight: 'bold',
  },
});

export const paragraph = tv({
  base: 'text-lg leading-normal',
  variants: {
    weight: {
      regular: 'font-normal',
      medium: 'font-medium',
    },
  },
  defaultVariants: {
    weight: 'regular',
  },
});

export const body = tv({
  base: 'text-base leading-normal',
  variants: {
    weight: {
      regular: 'font-normal',
      medium: 'font-medium',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    weight: 'regular',
  },
});

export const caption = tv({
  base: 'text-sm leading-[1.4]',
  variants: {
    weight: {
      regular: 'font-normal',
      medium: 'font-medium',
    },
  },
  defaultVariants: {
    weight: 'regular',
  },
});

export const button = tv({
  base: 'font-medium leading-normal',
  variants: {
    size: {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
