'use client';

import { Button } from '@nextui-org/button';
import { Code } from '@nextui-org/code';
import { useEffect } from 'react';

import { heading } from '@/theme/typography';

interface HomeErrorProps {
  error: Error;
  reset: () => void;
}

const HomeError: React.FC<HomeErrorProps> = ({ error, reset }) => {
  useEffect(() => {
    // Log the error to an error reporting service
    /* eslint-disable no-console */
    console.error('Root Error', error);
  }, [error]);

  return (
    <div className='container flex min-h-screen flex-col items-center justify-center gap-6 text-center'>
      <h2 className={heading({ size: 'h2' })}>Something went wrong!</h2>

      <div className='flex flex-col gap-4'>
        <p>{error.message}</p>
        <Code className='max-w-full p-4'>
          <pre className='text-wrap text-start'>{error.stack}</pre>
        </Code>
      </div>

      <Button
        className='border-neutral-black'
        radius='none'
        size='lg'
        variant='bordered'
        onClick={reset}
      >
        Try again
      </Button>
    </div>
  );
};

export default HomeError;
